import React, { Fragment } from "react";
import ErrorSec from "../components/Error";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { theme } from "../utils/theme";
import { ResetCSS } from "../utils/style/ResetCSS";
import { GlobalStyle, ContentWrapper } from "../containers/saas.style";
import Navbar from "../containers/Navbar";
import { DrawerProvider } from "../contexts/DrawerContext";
import SEO from "../components/SEO/SEO";
import PolicyLabel from "../components/PolicyPopup";

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <SEO title="Not Found" pathname="404" />
      <ResetCSS />
      <GlobalStyle />
      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
        </Sticky>
        <ErrorSec></ErrorSec>
      </ContentWrapper>
    </Fragment>
  </ThemeProvider>
);

export default NotFoundPage;
