import React from "react";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Image from "reusecore/src/elements/Image";
import Button from "reusecore/src/elements/Button";
import ErrorSvg from "../Illustration/404";
import { ErrorWrapper, ErrorConatent, ButtonWrapper } from "./error.style";

const ErrorSec = ({ btnStyle, imageWrapper, title, text }) => {
  const pageReload = () => {
    window.location.reload();
  };
  return (
    <ErrorWrapper>
      <ErrorConatent>
        <Box {...imageWrapper} className="image-wrapper">
          <Image
            src="https://sharify.s3.eu-central-1.amazonaws.com/f7b0f808-7b69-4dae-b16b-3d0ca19c1a8c.jpg"
            alt="404-illustration"
          />
        </Box>
        <Heading {...title} content="Page Not Found!" />
        <Text
          {...text}
          content="Oops! The page you are looking for has been removed or relocated."
        />
        <ButtonWrapper>
          <Button
            id="home-button"
            title="Go Home"
            {...btnStyle}
            ml={0}
            onClick={() => navigate("/")}
          />
        </ButtonWrapper>
      </ErrorConatent>
    </ErrorWrapper>
  );
};

ErrorSec.propTypes = {
  imageWrapper: PropTypes.object
};

ErrorSec.defaultProps = {
  imageWrapper: {
    mb: ["32px", "40px"]
  },
  title: {
    fontSize: ["26px", "32px", "32px", "32px"],
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: ["16px", "20px", "20px", "20px", "20px"],
    lineHeight: "1.31",
    textAlign: "center",
    fontWeight: "600"
  },
  text: {
    fontSize: ["18px", "18px", "20px", "20px", "20px"],
    color: "#343d48",
    lineHeight: "2",
    mb: ["16px", "20px", "20px", "20px", "20px"],
    textAlign: "center"
  },
  btnStyle: {
    minWidth: ["100%", "120px", "156px"],
    fontSize: ["13px", "16px"],
    fontWeight: "500",
    colors: "primaryWithBg",
    fontFamily: "sans-serif"
  }
};

export default ErrorSec;
