import React from "react";

const Svg404 = props => (
  <svg viewBox="0 0 262.87 196.34" {...props}>
    <defs>
      <style>
        {
          ".prefix__cls-2{fill:#363636}.prefix__cls-6{fill:none;stroke:#fff;stroke-miterlimit:10}.prefix__cls-7{fill:#666}.prefix__cls-8{fill:#7f7f7f}.prefix__cls-9{fill:#eebd4f}.prefix__cls-10{fill:#f7ec41}.prefix__cls-11{fill:#e9a727}"
        }
      </style>
    </defs>
    <title>{"cuper"}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__OBJECTS">
        <circle
          cx={131.61}
          cy={103.19}
          r={93.15}
          transform="rotate(-45 131.614 103.194)"
          opacity={0.1}
          fill="#f1cb6f"
        />
        <path
          className="prefix__cls-2"
          d="M0 123.59a6 6 0 01.72-3L45.6 32.23a5.85 5.85 0 015.54-3.57A6.3 6.3 0 0155 30.09a4.21 4.21 0 011.88 3.4 5.89 5.89 0 01-.54 2.32L15 117.36h35.07V82.53Q50.07 78 56 78a7.38 7.38 0 014.12 1.17 3.76 3.76 0 011.78 3.34v34.83h9.84A3.83 3.83 0 0175 119a6.35 6.35 0 011.16 3.73 6.25 6.25 0 01-1.25 3.89 3.88 3.88 0 01-3.22 1.65h-9.82v26.41a4 4 0 01-1.78 3.44 7 7 0 01-4.09 1.26 6.84 6.84 0 01-4.2-1.26 4.08 4.08 0 01-1.7-3.44v-26.41H4.65q-4.65 0-4.65-4.68zM186.69 123.59a6 6 0 01.72-3l44.88-88.33a5.85 5.85 0 015.54-3.57 6.3 6.3 0 013.85 1.43 4.21 4.21 0 011.88 3.4 5.89 5.89 0 01-.54 2.32l-41.31 81.55h35V82.53q0-4.52 5.9-4.51a7.38 7.38 0 014.12 1.17 3.76 3.76 0 011.78 3.34v34.83h9.84a3.83 3.83 0 013.31 1.64 6.35 6.35 0 011.16 3.73 6.25 6.25 0 01-1.25 3.89 3.88 3.88 0 01-3.22 1.65h-9.84v26.41a4 4 0 01-1.78 3.44 7 7 0 01-4.12 1.26 6.84 6.84 0 01-4.2-1.26 4.08 4.08 0 01-1.7-3.44v-26.41h-45.37q-4.65 0-4.65-4.68z"
        />
        <path
          fill="#d8a045"
          d="M167.2 59.98l-10.12 8.87-7.53-12.47-11.42 8.83-2.38 13.5-15.02 6.5v28.56h26.74l12.73-15.32 10.64-21.55-3.64-16.92z"
        />
        <path
          d="M93.5 72.89s-2.5 12.19 14.69 36.57c0 0 5.63 6.88 4.69 18.45 0 0 .8 11 7 11.39s30.13 0 30.13 0a9.4 9.4 0 007.64-9.19c.34-8.05 7.54-25.35 10.79-29.29s13.88-22.1 7.88-40.77c0 0-7.88-16.79-11.65-17.65l3.77 26.39-2.91 5.13h-4.11l-9.59 14.22-15.25 1.54-7.54 13-4.28-3.76-1.2-9.77-14.21-.15-6.85-12.16z"
          fill="#f1cb6f"
        />
        <g opacity={0.5}>
          <path
            className="prefix__cls-6"
            d="M129.05 101.69l2.56 6.99-1.28 7.72M131.61 107.83l9.59 4.27-4.79 19.42M151.84 88.14l3.18 10.44-1.59 9.25M155.02 98.58l6.53-7.64"
          />
        </g>
        <path
          className="prefix__cls-7"
          d="M149.42 146.49h-27.33a3 3 0 01-3-3 3 3 0 013-3h27.33a3 3 0 013 3 3 3 0 01-3 3zM149.42 157.07h-27.33a3 3 0 01-3-3 3 3 0 013-3h27.33a3 3 0 013 3 3 3 0 01-3 3z"
        />
        <path
          className="prefix__cls-8"
          d="M121.9 145.65h27.7a2.83 2.83 0 012.83 2.83 2.84 2.84 0 01-2.83 2.83h-27.7a2.85 2.85 0 01-2.83-2.83 2.84 2.84 0 012.83-2.83zM124.07 158.38H148s-10.58 14.93-23.93 0z"
        />
        <path
          className="prefix__cls-9"
          d="M115.66 61.15s-.39-.21-1-.59c-.32-.19-.73-.41-1.17-.68s-1-.56-1.48-.9c-1.1-.64-2.33-1.43-3.66-2.23l-1-.62c-.34-.2-.69-.4-1-.62-.69-.43-1.41-.81-2.12-1.23s-1.44-.77-2.13-1.18L99.93 52l-2-.91c-.65-.25-1.26-.52-1.84-.76s-1.09-.33-1.6-.51-.92-.24-1.28-.36c-.72-.19-1.15-.33-1.15-.33s.42-.12 1.18-.25a7.14 7.14 0 011.38-.18h1.82c.67 0 1.38.16 2.14.28s1.51.38 2.32.61 1.58.58 2.38.92 1.57.76 2.33 1.2 1.51.88 2.2 1.39a24.5 24.5 0 012 1.51 28.41 28.41 0 013.23 3.06 23.78 23.78 0 012 2.46 11.81 11.81 0 01.62 1.02z"
        />
        <path
          className="prefix__cls-10"
          d="M140.86 56a12.71 12.71 0 01.35-1.68c.13-.54.33-1.17.57-1.89a24 24 0 01.95-2.38c.19-.43.39-.88.61-1.34s.49-.91.75-1.38c.5-1 1.15-1.9 1.79-2.9.34-.48.72-.95 1.09-1.44a17.54 17.54 0 011.18-1.43 30.77 30.77 0 012.66-2.7l1.43-1.25 1.54-1.12a22.3 22.3 0 013.13-1.92 20.43 20.43 0 013.09-1.45c.51-.19 1-.41 1.48-.57l1.44-.36a23.21 23.21 0 012.5-.56c.76-.09 1.43-.14 2-.19 1.09-.09 1.71-.1 1.71-.1s-.58.27-1.57.68c-.5.2-1.1.45-1.8.7s-1.41.7-2.23 1.06l-1.26.6-1.3.74a29.06 29.06 0 00-2.73 1.67l-1.39.94c-.49.29-.9.7-1.37 1l-1.38 1.07-1.31 1.2c-.91.75-1.7 1.63-2.53 2.44S148.71 45.18 148 46s-1.43 1.67-2 2.51-1.22 1.6-1.73 2.35-1 1.44-1.41 2-.77 1.17-1 1.63c-.69.94-1 1.51-1 1.51zM124.9 52.49l-5.13-5.38-2.48-2.56-2.8-2.81c-.94-1-2-1.93-3-3s-2.11-2-3.2-3-2.15-2-3.26-2.88-2.14-1.88-3.24-2.69-2.08-1.71-3.12-2.41l-1.48-1.08a17 17 0 00-1.39-1c-.92-.59-1.75-1.17-2.5-1.68s-1.43-.87-2-1.21c-1.11-.68-1.74-1.1-1.74-1.1s.73.18 2 .56c.63.2 1.41.4 2.26.74s1.8.75 2.83 1.22A35.28 35.28 0 0199.86 26a38.18 38.18 0 013.46 2.16c1.19.77 2.34 1.66 3.51 2.57s2.28 1.93 3.43 2.91 2.17 2.09 3.22 3.13 2 2.14 2.91 3.21 1.78 2.12 2.56 3.15 1.49 2 2.13 2.93a53.18 53.18 0 012.92 4.6c.59 1.14.9 1.83.9 1.83z"
        />
        <path
          className="prefix__cls-9"
          d="M130.71 41.17v-1.56a40.29 40.29 0 01.43-4.09c.14-.84.27-1.75.49-2.7s.41-2 .69-3 .58-2.08.92-3.15.75-2.12 1.13-3.19.88-2.1 1.34-3.12 1-2 1.5-2.93a41.07 41.07 0 013.23-4.89c.54-.67 1.07-1.26 1.53-1.78a13.48 13.48 0 011.29-1.26c.73-.68 1.18-1 1.18-1s-.25.53-.72 1.4c-.22.44-.54.93-.83 1.55s-.62 1.3-1 2-.71 1.58-1.13 2.42-.75 1.8-1.19 2.72-.81 1.93-1.22 2.93l-1.22 3c-1.58 4.13-3.16 8.27-4.35 11.37-.59 1.57-1.12 2.87-1.49 3.78s-.58 1.5-.58 1.5z"
        />
        <path
          className="prefix__cls-10"
          d="M87.3 27.39A21.31 21.31 0 0186.1 24a33.7 33.7 0 01-.76-3.42 34.22 34.22 0 01-.41-3.49 20.18 20.18 0 010-3.56 20.35 20.35 0 011.2 3.35 34.83 34.83 0 011.17 6.91 20.18 20.18 0 010 3.6z"
        />
        <path
          className="prefix__cls-10"
          d="M79.82 23.64a20.19 20.19 0 012.88-2.13 34.8 34.8 0 016.3-3.16 20.3 20.3 0 013.43-1 20.69 20.69 0 01-2.87 2.13 32.24 32.24 0 01-3.08 1.73 33.26 33.26 0 01-3.23 1.42 21.6 21.6 0 01-3.43 1.01z"
        />
        <path
          className="prefix__cls-11"
          d="M91.48 53.83a12.1 12.1 0 01-1.19-1.4c-.34-.43-.7-1-1.07-1.51s-.73-1.2-1.09-1.82a26.89 26.89 0 01-1.66-3.6 12.34 12.34 0 01-.54-1.76 13.29 13.29 0 011.19 1.4 26.09 26.09 0 012.15 3.33c.34.64.68 1.28 1 1.89s.53 1.2.72 1.72a14.4 14.4 0 01.49 1.75z"
        />
        <path
          className="prefix__cls-11"
          d="M84.66 52.94a12.76 12.76 0 011-1.56c.32-.45.71-1 1.15-1.47s.93-1 1.43-1.58a30.23 30.23 0 013-2.66 12.57 12.57 0 011.54-1 13.73 13.73 0 01-1 1.56 28.55 28.55 0 01-2.58 3c-.52.51-1 1-1.54 1.47s-1 .86-1.44 1.19a14.1 14.1 0 01-1.56 1.05zM140.16 5.76a13.41 13.41 0 012-.27c.59 0 1.29-.08 2-.08s1.53 0 2.32.07a30.57 30.57 0 014.31.61 14.32 14.32 0 012 .55 15.61 15.61 0 01-2 .27 31.59 31.59 0 01-4.36 0c-.79 0-1.57-.14-2.31-.25s-1.42-.22-2-.36a14.9 14.9 0 01-1.96-.54z"
        />
        <path
          className="prefix__cls-11"
          d="M145 0a14.6 14.6 0 01.84 1.85 20.4 20.4 0 01.67 1.93c.22.72.43 1.48.61 2.26a29.84 29.84 0 01.68 4.33 14.32 14.32 0 010 2 14.82 14.82 0 01-.84-1.84 31.55 31.55 0 01-1.28-4.19c-.17-.78-.32-1.56-.43-2.3s-.2-1.43-.25-2A14.47 14.47 0 01145 0z"
        />
        <path
          className="prefix__cls-10"
          d="M167.65 26a19 19 0 013 1.67A34.73 34.73 0 01176 32a19.83 19.83 0 012.28 2.61 19.81 19.81 0 01-3-1.67 30.4 30.4 0 01-2.76-2 30.53 30.53 0 01-2.56-2.27 20.4 20.4 0 01-2.31-2.67z"
        />
        <path
          className="prefix__cls-10"
          d="M175.53 23.92a20 20 0 01-.72 3.41c-.32 1.11-.7 2.19-1.12 3.25a32.6 32.6 0 01-1.44 3.12 19.92 19.92 0 01-1.84 3 20.24 20.24 0 01.72-3.41c.31-1.1.7-2.18 1.12-3.25a33.77 33.77 0 011.44-3.12 19.92 19.92 0 011.84-3z"
        />
      </g>
    </g>
  </svg>
);

export default Svg404;
